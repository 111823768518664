import React, { useEffect, useState } from 'react';
// import ColumnAuctionRedux from '../components/ColumnAuctionRedux';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import ItemsOnAuction from '../components/ItemsOnAuction';
import {
  connectWallet,
  getCurrentWalletConnected,
  loadContracts
} from "../../core/nft/interact";
const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #403f83;
    border-bottom: solid 1px #403f83;
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: rgba(255, 255, 255, .5);;
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;




function Explore() {
  const [nft, setNFT] = useState({})
  const [auction, setMarketplace] = useState({})
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [contractLoading, setContractLoading] = useState(false);

  const Contracts = async () => {
    const { marketplace, nft, auction } = await loadContracts()
    setMarketplace(auction)
    setNFT(nft)
    setContractLoading(true);
  }
  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          // setStatus("Fill in the text-field above.");
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the top right button.");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
    Contracts()
  };

  useEffect(() => {
    async function getExistingWallet() {
      const { address, status } = await getCurrentWalletConnected();

      setWallet(address);
      setStatus(status);

      addWalletListener();
      Contracts()
    }
    getExistingWallet();
  }, []);
  return (
    <div>

      <GlobalStyles />
      <section className='jumbotron breadcumb no-bg mt-5' style={{ backgroundImage: `url(${'./img/background/subheader.jpg'})` }}>
        <div className='mainbreadcumb'>
          <div className='container'>
            <div className='row m-10-hor'>
              <div className='col-12'>
                <h1 className='text-center'>Live Auction</h1>
              </div>
            </div>
          </div>
        </div>
      </section>


      {walletAddress.length && contractLoading ? (
        <>
          <section className='container'>
            <ItemsOnAuction auction={auction} nft={nft} />
          </section>

          <section className='container'>
            {/* <ColumnAuctionRedux /> */}
          </section>
        </>
      ) : (
        <div>
          {
            <button id="walletButton" className="btn-main" onClick={connectWalletPressed}>
              Connect Metamask
            </button>
          }
        </div>
      )}


      <Footer />
    </div>

  );
}
export default Explore;