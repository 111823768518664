require('dotenv').config();
//d960904acaf629351128
//6622708f982b64f9da8b4707bf0247ad63311f99eafd15d9b53260e9aa630b3b
const key = '91238d721d3bcd924324';
const secret = '57adeecd8d27a9586b8540a09c7b126a60dfd8cafa8aae310e0d88f68456bf3c';
const axios = require('axios');

export const pinJSONToIPFS = async(JSONBody) => {
    const url = `https://api.pinata.cloud/pinning/pinJSONToIPFS`;
    return axios
        .post(url, JSONBody, {
            headers: {
                pinata_api_key: key,
                pinata_secret_api_key: secret,
            }
        })
        .then(function (response) {
           return {
               success: true,
               pinataUrl: "https://gateway.pinata.cloud/ipfs/" + response.data.IpfsHash
           };
        })
        .catch(function (error) {
            console.log(error)
            return {
                success: false,
                message: error.message,
            }
           
        });
};