import React, { useState } from "react";
import SliderMain from "../components/SliderMain";
import CarouselCollectionRedux from "../components/CarouselCollectionRedux";
import AuthorListRedux from "../components/AuthorListRedux";
import Footer from "../components/footer";
import ItemsOnSell from "../components/ItemsOnSell";
import { navigate } from '@reach/router';
import { Modal } from 'react-bootstrap'

const Home = () => {

  const [modalShow, setModalShow] = useState(false);

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Step 3 & 4
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="nft__item m-0">
            <div className="nft__item_info">
              <span>
                <h3>Procced to create NFT</h3>
              </span>
              <input
                type="button"
                id="submit"
                onClick={() => navigateTo(`/create`)}
                className="btn-main"
                value="Create Nft"
              /><div className="spacer-10"></div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }


  const navigateTo = (link) => {
    navigate(link);
  }
  return (
    <>
      <div>
        <section className="jumbotron breadcumb no-bg h-vh landingcolor">
          <SliderMain />
        </section>
        <br />
        <br />
        <br />

        <section className="container no-bottom">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-start hdbr">
                <h2>LIVE COLLECTION</h2>
              </div>
            </div>
            <div className="col-lg-12">
              <ItemsOnSell />
              {/* <CarouselNewRedux /> */}
            </div>

            <div className="row" >
              <div className="col-lg-3 md-3 sm-2 text-center mb-3">
                <button className="stepbox cardh" onClick={() => navigateTo(`/register`)}>
                  <h2 className="step text-center">Step 1</h2>
                  <div className=" demo-icon-wrap">
                    <i
                      className="fa fa-fw"
                      aria-hidden="true"
                      title="Copy to use user-plus"
                    >
                      
                    </i>

                    <span className="text-grey">[&amp;#xf234;]</span>
                    <br />
                    <br />
                    <h6>CREATE NEW ACCOUNT</h6>
                  </div>
                </button>
              </div><br />
              <div className="col-lg-3 md-3 sm-2 text-center mb-3">
                <button className="stepbox cardh" onClick={() => navigateTo(`/create`)} >
                  <h2 className="step text-center " style={{ marginTop: '18px' }} >Step 2</h2>
                  <div className="demo-icon-wrap">
                    <i
                      className="fa fa-fw"
                      aria-hidden="true"
                      title="Copy to use upload"
                    >
                      
                    </i>

                    <span className="text-grey" >[&amp;#xf093;]</span>
                    <br />
                    <br />
                    <h6>UPLOAD YOUR FAVORITE CAR PHOTOS</h6>
                  </div>
                </button>
              </div><br />
              <div className="col-lg-3 md-3 sm-2 text-center mb-3">
                <button className="stepbox cardh" onClick={() => setModalShow(true)}>
                  <h2 className="step text-center ">Step 3</h2>
                  <div className="demo-icon-wrap">
                    <i
                      className="fa fa-fw"
                      aria-hidden="true"
                      title="Copy to use check-square-o"
                    >
                      
                    </i>

                    <span className="text-grey">[&amp;#xf046;]</span>
                    <br />
                    <br />
                    <h6>PICK YOUR STYLE</h6>
                  </div>
                </button>
              </div><br />
              <div className="col-lg-3 md-3 sm-2 text-center mb-3"
                style={{ marginRight: "0px" }}
              >

                <button className="stepbox cardh" onClick={() => setModalShow(true)} >
                  <h2 className="step text-center " style={{ marginTop: '18px' }}>Step 4</h2>
                  <div className=" demo-icon-wrap">
                    <i
                      className="fa fa-fw"
                      aria-hidden="true"
                      title="Copy to use file"
                    >
                      
                    </i>
                    <span className="text-grey">[&amp;#xf15b;]</span>
                    <br />
                    <br />
                    <h6>APPROVE YOUR ORIGNAL VINTAGE CAR POTRATE</h6>
                  </div>
                </button>
              </div><br />
            </div>
          </div>
        </section>

        <section className="container no-bottom">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-start hdbr">
                <h2>POPULAR COLLECTION</h2>
                {/* <div className="small-border"></div> */}
              </div>
            </div>
            <div className="col-lg-12">
              <CarouselCollectionRedux />
            </div>
          </div>
        </section>

        <section className="container no-bottom">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-start hdbr">
                <h2>TOP SELLERS</h2>
                {/* <div className="small-border"></div> */}
              </div>
            </div>
            <div className="col-lg-12">
              <AuthorListRedux />
            </div>
          </div>
        </section>

        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
        <Footer />
      </div>
    </>
  );
};
export default Home;

// fa-upload
// fa-user-plus
// fa-check-square-o
// fa-file-text-o
