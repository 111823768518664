import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import * as selectors from '../../store/selectors';
import api from "../../core/api";
// import { fetchHotCollections } from "../../store/actions/thunks";

import MyListedItems from "../components/myListedItems";
import ItemsOnSell from '../components/ItemsOnSell';
import ItemsOnAuction from '../components/ItemsOnAuction';
import {
  connectWallet,
  getCurrentWalletConnected,
  loadContracts, accountChangeHandler
} from "../../core/nft/interact";

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #403f83;
    border-bottom: solid 1px #403f83;
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: rgba(255, 255, 255, .5);;
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;
function Colection() {
  const [openMenu, setOpenMenu] = React.useState(true);
  const [openMenu1, setOpenMenu1] = React.useState(false);

  // const [account, setAccount] = useState(null)
  const [nftCon, setNFT] = useState({})
  const [marketplace, setMarketplace] = useState({})
  const [auction, setAuction] = useState({})
  // const [loading, setLoading] = useState(true)
  const [data, setdata] = useState("");
  const [contractLoaded, setContractLoaded] = useState(false);

  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");

  const Contracts = async () => {
    const { marketplace, nft, auction } = await loadContracts()
    setMarketplace(marketplace)
    setNFT(nft)
    setAuction(auction)
    setContractLoaded(true);
    setdata(await accountChangeHandler());

  }

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          // setStatus("Fill in the text-field above.");
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the top right button.");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
    Contracts()

  };


  const handleBtnClick = () => {
    setOpenMenu(!openMenu);
    setOpenMenu1(false);
    document.getElementById("Mainbtn").classList.add("active");
    document.getElementById("Mainbtn1").classList.remove("active");
  };
  const handleBtnClick1 = () => {
    setOpenMenu1(!openMenu1);
    setOpenMenu(false);
    document.getElementById("Mainbtn1").classList.add("active");
    document.getElementById("Mainbtn").classList.remove("active");
  };

  //const dispatch = useDispatch();
  // const hotCollectionsState = useSelector(selectors.hotCollectionsState);
  //const hotCollections = hotCollectionsState.data ? hotCollectionsState.data[0] : {};

  useEffect(() => {
    async function getExistingWallet() {
      const { address, status } = await getCurrentWalletConnected();

      setWallet(address);
      setStatus(status);

      addWalletListener();
      Contracts()
      //dispatch(fetchHotCollections(collectionId));

    }
    getExistingWallet();
  }, []);


  return (
    <div>
      <GlobalStyles />

      <section className='jumbotron breadcumb no-bg mt-5' style={{ backgroundImage: `url(${'./img/background/author_banner.jpg'})` }}>
        <div className='mainbreadcumb'>
          <div className='container'>
            <div className='row m-10-hor'>
              <div className='col-12'>
                <h1 className='text-center'>Collection</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      {walletAddress.length && contractLoaded ? (
        <>

          <section className='container d_coll no-top no-bottom'>
            <div className='row'>
              <div className="col-md-12">
                <div className="d_profile">
                  <div className="profile_avatar">

                    <div className="profile_name">
                      <h4><br />

                        <div className="clearfix"></div>
                        {
                          <span id="wallet" className="profile_wallet">{walletAddress}</span>
                        }
                        <button id="btn_copy" title="Copy Text">Copy</button><br />
                        <span id="wallet" className="profile_wallet"> {data}</span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className='container no-top'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className="items_filter">
                  <ul className="de_nav">
                    <li id='Mainbtn' className="active"><span onClick={handleBtnClick}>On Sale</span></li>
                    <li id='Mainbtn1' className=""><span onClick={handleBtnClick1}>Owned</span></li>
                  </ul>
                </div>
              </div>
            </div>
            {openMenu && (
              <div id='zero1' className='onStep fadeIn'>
                <ItemsOnAuction auction={auction} nft={nftCon} />
                <ItemsOnSell marketplace={marketplace} nft={nftCon} />

              </div>
            )}
            {openMenu1 && (
              <div id='zero2' className='onStep fadeIn'>
                <MyListedItems marketplace={marketplace} nftCon={nftCon} account={walletAddress} />

              </div>
            )}
          </section>
        </>
      ) : (
        <div >
          {
            <button id="walletButton" className="btn-main" onClick={connectWalletPressed}>
              Connect Metamask
            </button>
          }
        </div>
      )}

      <Footer />
    </div>
  );
}
export default Colection;