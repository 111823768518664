import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import Slider from "react-slick";
import { carouselNew } from "./constants";
import axios from "axios";
import { Button, Modal } from 'react-bootstrap'
import { getCurrentWalletConnected, loadContracts ,accountChangeHandler} from "../../core/nft/interact";

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
`;
//react functional component
const ItemsOnSell = () => {

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([]);

  const [modalShow, setModalShow] = useState(false);
  const [currentItem, setCurrentItem] = useState([]);
  const [img, setImg] = useState(null);
 
  const loadMarketplaceItems = async () => {
    await axios
    .get("https://exotic-nft-3.herokuapp.com/nft/getNft")
    .then((res) => setData(res.data))
    .catch((err) => console.log(err, "it has an error"));

    setLoading(false)
  }

  const removeFromMarketplace = async (id) => {
    try {
       const res = await axios.put(`https://exotic-nft-3.herokuapp.com/nft/createNft/${id}`, { "isBuy": true })
     loadMarketplaceItems();
    } catch (err) {
      console.log(err);
    }
  }

  const buyMarketItem = async (item) => {
    const { address, status } = await getCurrentWalletConnected()

    console.log(address, status);
    //console.log("item: ",item)
    if (address) {
     const balance =  await accountChangeHandler();

      const { marketplace } = await loadContracts()
      //console.log(marketplace)
      const totalPrice = await marketplace.getTotalPrice(item.id);
      const nftId = Number(item.id)

      if(balance > item.price)
      {
        await (await marketplace.purchaseItem(nftId, { value:totalPrice })).wait()
      removeFromMarketplace(item._id)
      loadMarketplaceItems();
      }

      setModalShow(false);

    } else {
      console.log(status);
    }
  }

  function MyVerticallyCenteredModal(props) {
    if (currentItem == null) {
      return (
        <></>
      )
    } else {

      return (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              NFT Preview
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="nft__item m-0">
              <div className="de_countdown">
                {/* <Clock deadline={duration} /> */}
              </div>
              <div className="author_list_pp">
                <span>
                  <img
                    className="lazy"
                    src="./img/author/author-1.jpg"
                    alt=""
                  />
                  <i className="fa fa-check"></i>
                </span>
              </div>
              <div className="nft__item_wrap">
                <span>
                  <img
                    style={{ height: "12rem" }}
                    src={`data:image/png;base64,${img}`}
                    id="get_file_2"
                    className="lazy nft__item_preview"
                    alt=""
                  />
                </span>
              </div>
              <div className="nft__item_info">
                <span>
                  <h4>{currentItem.name}</h4>
                </span>
                <div className="nft__item_price">
                  {currentItem.price} ETH
                </div>
                <div className="nft__item_action">
                  <span>Place a bid</span>
                </div>
                <div className="nft__item_like">
                  <i className="fa fa-heart"></i>
                  <span>0</span>
                </div>
                <input
                  type="button"
                  id="submit"
                  onClick={() => buyMarketItem(currentItem)}
                  className="btn-main"
                  value="Buy Nft"
                /><div className="spacer-10"></div>
                <input
                  type="button"
                  id="submit"
                  onClick={props.onHide}
                  className="btn-main"
                  value="Decline"
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <input
                  type="button"
                  id="submit"
                  onClick={props.onHide}
                  className="btn-main"
                  value="Understood"
                />
          </Modal.Footer>
        </Modal>
      );
    }

  }
  const showModel = (item) => {
    const base64String = btoa(
      String.fromCharCode(...new Uint8Array(item.img.data.data))
    );
    setImg(base64String)
    setModalShow(true);
    setCurrentItem(item);
  }


  useEffect(() => {
    
    loadMarketplaceItems()
  },[] );
  if (loading) return (
    <main style={{ padding: "1rem 0" }}>
      <h2 style={{ marginTop: '120px', marginBottom: '120px' }}>Loading...</h2>
    </main>
  )

  return (
    <div className="nft">
      {/* <h1>Image uploading react</h1>
      {data.map((singleData) => {
        const base64String = btoa(
          String.fromCharCode(...new Uint8Array(singleData.img.data.data))
        );
        return <img src={`data:image/png;base64,${base64String}`} width="300"/>
      })} */}
      <Slider {...carouselNew}>
        {data &&

          data.map((item, idx) => {
            const base64String = btoa(
              String.fromCharCode(...new Uint8Array(item.img.data.data))
            );

            return (
              <div key={idx} className='itm'>
                <div className="d-item">
                  <div className="nft__item ">
                    <br />
                    <br />
                    <div className="nft__item_wrap" style={{ height: '10rem' }}>
                      <Outer>
                        <span>
                          <img style={{ maxHeight: '14rem' }}
                            src={`data:image/png;base64,${base64String}`}

                            className="lazy nft__item_preview" alt="" />

                        </span>
                      </Outer>
                    </div>

                    <div className="nft__item_info">
                      <span >
                        <h4>{item.name}</h4>
                      </span>
                      {
                        <div className="nft__item_price">
                          {item.price} ETH
                          {/* <span onClick={() => buyMarketItem(item)}>Buy</span> */}
                          <span onClick={() => showModel(item)}>Buy</span>


                        </div>
                      }

                      <div className="nft__item_like">
                        <i className="fa fa-heart"></i><span>{item.id}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })

        }
      </Slider>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
};

export default ItemsOnSell;