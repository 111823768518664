import React from "react";
import { Link } from "@reach/router";
import Logo1 from "../../../src/img/Logo1.png";

const footer = () => (
  <footer className="footer-light mb-5">
    <div className="container">
      <div className="row">
        <div className="col-lg-3 col-md-6 col-sm-2 col-xs-1">
          <div className="widget">
            <div className="row">
              <div className="col-5 text-center">
                <img className="fimg" src={Logo1} alt="" />
              </div>
              <div className="col-7">
                <br />
                <h5 className="mx-2">EXOTIC AUTOS NFT</h5>
                <p className="small padr mx-2">
                  With Exotic Autos NFT you can share your NFT and digital story
                  with our exclusive licensed NFT drops from exotic and classic
                  car collectors just like you and share your same passions and
                  memory’s.
                </p>
              </div>
              <p className="small padr mx-2 mt-2">
                {" "}
                We offer car and auto enthusiasts the opportunity to
                  collect some of the most unique, original automotive digital
                  assets. <br />
                These assets can be explored and purchased in Metaverse as well
                as other platforms and protocols. Our mission is to allow the
                collector and Exotic Autos NFT to bridge the gap between the
                physical and digital collectible worlds. <br />
                Your classic car memories are prices less but your experiences
                and assets can be shared and monetized and seen by all level of
                collectors globally.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-md-3 col-sm-4 col-xs-1">
          <div className="widget padl">
            <h4 className="pb-1">My Account</h4>
            <ul>
              <li className="py-1">
                <Link to="">All NFTs</Link>
              </li>
              <li className="py-1">
                <Link to="">Art</Link>
              </li>
              <li className="py-1">
                <Link to="">Music</Link>
              </li>
              <li className="py-1">
                <Link to="">Domain Names</Link>
              </li>
              {/* <li>
                <Link to="">Virtual World</Link>
              </li>
              <li>
                <Link to="">Collectibles</Link>
              </li> */}
            </ul>
          </div>
        </div>
        <div className="col-lg-2 col-md-3 col-sm-4 col-xs-1">
          <div className="widget padl">
            <h4 className="pb-1">Resources</h4>
            <ul>
              <li className="py-1">
                <Link to="">Help Center</Link>
              </li>
              <li className="py-1">
                <Link to="">Partners</Link>
              </li>
              <li className="py-1">
                <Link to="">Suggestions</Link>
              </li>
              <li className="py-1">
                <Link to="">Discord</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-2 col-md-3 col-sm-4 col-xs-1">
          <div className="widget padl">
            <h4 className="pb-1">Company</h4>
            <ul>
              <li className="py-1">
                <Link to="">Community</Link>
              </li>
              <li className="py-1">
                <Link to="">Documentation</Link>
              </li>
              <li className="py-1">
                <Link to="">Brand Assets</Link>
              </li>
              <li className="py-1">
                <Link to="">Blog</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-2 col-xs-1">
          <div className="widget padl">
            <h4 className="pb-1">Subscribe us</h4>
            {/* <p>
              Signup for our newsletter to get the latest news in your inbox.
            </p> */}
            <form
              action="#"
              className="row form-dark"
              id="form_subscribe"
              method="post"
              name="form_subscribe"
            >
              <div className="col text-center">
                <input
                  className="form-control"
                  id="txt_subscribe"
                  name="txt_subscribe"
                  placeholder="enter your email"
                  type="text"
                />
                <Link to="" id="btn-subscribe">
                  <i className="arrow_right bg-color-secondary"></i>
                </Link>
                <div className="clearfix"></div>
              </div>
            </form>
            {/* <div className="spacer-10"></div>
            <small>Your email is safe with us. We don't spam.</small> */}
          </div>
          <div className="subfooter">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="de-flex">
                    <div className="de-flex-col">
                      <div className="social-icons">
                        <span onClick={() => window.open("", "_self")}>
                          <i className="fa fa-facebook fa-lg"></i>
                        </span>
                        <span onClick={() => window.open("", "_self")}>
                          <i className="fa fa-twitter fa-lg"></i>
                        </span>
                        <span onClick={() => window.open("", "_self")}>
                          <i className="fa fa-linkedin fa-lg"></i>
                        </span>
                        <span onClick={() => window.open("", "_self")}>
                          <i className="fa fa-pinterest fa-lg"></i>
                        </span>
                        <span onClick={() => window.open("", "_self")}>
                          <i className="fa fa-rss fa-lg"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
);
export default footer;