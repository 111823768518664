import { pinJSONToIPFS } from "./pinata.js";
import { ethers } from 'ethers'
import NFT from './nft.json'
import Marketplace from './marketplace.json'
import Auction from './auction.json'

require("dotenv").config();

export const loadContracts = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()
  //CF1288F34A59e44a9dFEFF411A14b514968CeF7f
  // Get deployed copies of contracts  
  const marketplace = new ethers.Contract("0x7A87e841b2F4CBD9E1FFe03e151a9E322232801C", Marketplace.abi, signer)
  //setMarketplace(marketplace)
  const nft = new ethers.Contract("0xf704Ad28D2f3C164DE96A1013D8EA5a666d9B498", NFT.abi, signer)
  //setNFT(nft)
  const auction = new ethers.Contract(
    "0xDA82Dc03617111e0b95A42cA0796B87A597eb780",
    Auction.abi,
    signer
  );
  return {
    marketplace: marketplace,
    nft: nft,
    auction: auction
  }

}

export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const obj = {
        status: "Metamask successfuly connected.",
        address: addressArray[0],
      };
      return obj;
    } catch (err) {
      return {
        address: "",
        status: "Something went wrong: " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: "",
        };
      } else {
        return {
          address: "",
          status: "🦊 Connect to Metamask using the top right button.",
        };
      }
    } catch (err) {
      return {
        address: "",
        status: "Something went wrong: " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};

export const accountChangeHandler = async () => {

  const {address} = await getCurrentWalletConnected()

  // Requesting balance method
  const balance = await window.ethereum
  .request({
    method: "eth_getBalance",
    params: [address, "latest"]
  })
  
  return (ethers.utils.formatEther(balance));
 
};

export const mintNFT = async (url, name, description, price, minBid, duration, nft, marketplace, auction, select) => {
  if (url === "" || name.trim() === "" || description.trim() === "") {
    return {
      success: false,
      status: "Please make sure all fields are completed before minting.",
    };
  }

  const metadata = {};
  metadata.name = name;
  metadata.image = url;
  metadata.description = description;

  const pinataResponse = await pinJSONToIPFS(metadata);
  if (!pinataResponse.success) {
    return {
      success: false,
      status: "Something went wrong while uploading your tokenURI.",
    };
  }
  const tokenURI = pinataResponse.pinataUrl;
  console.log(tokenURI)

  const link = await nft.mint(tokenURI)
  var nftId;
  if (select === 1) {
    await nft.setApprovalForAll(marketplace.address, true)
    const id = await nft.tokenCount()
    // add nft to marketplace
    const listingPrice = ethers.utils.parseEther(price.toString())
    await marketplace.makeItem(nft.address, id, listingPrice)
    
    nftId = await marketplace.itemCount();
  } else {
    await nft.setApprovalForAll(auction.address, true)
    const id = await nft.tokenCount()
    // add nft to marketplace
    const listingPrice = ethers.utils.parseEther(minBid.toString())
    duration = (new Date(duration).getTime()) / 1000;
    await auction.createTokenAuction(nft.address, id, listingPrice, duration)
    //nftId = id;

  }


  return {
    success: true,
    status:
      "Check out your transaction on Etherscan: https://testnet.bscscan.com/tx/" +
      link.hash, id: nftId,
  };

};
