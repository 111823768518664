import React from "react";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const slidermain = () => (
  <div className="container">
    <div className="row align-items-center mt-5">
      <div className="col-md-5">
        <div className="spacer-single mt-5"></div>
        <Reveal
          className="onStep"
          keyframes={fadeInUp}
          delay={0}
          duration={600}
          triggerOnce
        >
          {/* <h6 className=""><span className="text-uppercase color">Gigaland Market</span></h6> */}
        </Reveal>
        <div className="spacer-10"></div><br /><br />
              <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={600} triggerOnce>
              <h1 style={{color:"white"}} className="">EXOTIC AUTOS NFT</h1>
              <h3 style={{color:"white"}} className="">What is Exotic Autos NFT?</h3>
              </Reveal>
        <Reveal
          className="onStep"
          keyframes={fadeInUp}
          delay={600}
          duration={600}
          triggerOnce
        >
          <p style={{ color: "white" }} className="">
              Exotic Autos NFT is the only exclusive services offering
              non-fungible tokens crafted around both a digital and physical
              asset (fine art) designed to memorialize the car owners memory’s
              of their prized collections.Specifically, we are the only
              services that takes your artwork of your classic or exotic auto
              and offer not only the protections of the NFT, but also the
              capabilities to “Stake” your NFT. This process also includes
              collectors to offer their prized, exclusive car images, photos,
              videos and memory’s of there classic or exotic car, including
              animated clips, or even a live racing events all for the world to
              see and buy in the NFT marketplace. <br />
          </p>
        </Reveal>
      </div>
      <div className="col-md-7 xs-hide">
        <Reveal
          className="onStep hht"
          keyframes={fadeIn}
          delay={900}
          duration={1500}
          triggerOnce
        >
          <img src="./img/misc/car1.png" className="lazy img-fluid hht" alt="" />
          {/* <img src="./img/misc/nft.png" className="lazy img-fluid" alt=""/> */}
        </Reveal>
      </div>
    </div>
  </div>
);
export default slidermain;